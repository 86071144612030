import React from "react";

import IconChevronLeft from "../../icons/IconChevronLeft";
import {Link} from "gatsby";

const AppMeta = () => {

    return (
        <section className="article-meta">
            <div className="container container--medium">
                <div className="article-meta__wrapper">
                    <div className="article-meta__row">
                        <ul className="article-meta__info">
                            <li className="article-meta__info-item">
                                <Link to="/">
                                    <IconChevronLeft/>
                                    Home
                                </Link>
                            </li>
                            <li className="article-meta__info-item">
                                <Link to="/apps/">Apps</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AppMeta;
