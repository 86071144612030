import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Accordion from "../components/Accordion";
import {useLocation} from "@reach/router";

const Faqs = ({ title, questions, bottomOverlayBackground }) => {
	const location = useLocation();
	const [initialOpenAccordion, setInitialOpenAccordion] = useState(null);

	useEffect(() => {
		if(typeof window !== "undefined") {
			const match = location.hash.match(/question-(\d+)/);

			if (!match) return;

			const index = parseInt(match[1], 10);

			if (!isNaN(index) && index < questions.length) {
				const currentAccordion = document.querySelector(`[data-id="question-${index}"]`);

				currentAccordion?.scrollIntoView({
					behavior: "smooth",
					block: "center"
				});

				setInitialOpenAccordion(index);
			}
		}
	}, [])

	return (
		<section
			className="faqs"
			style={{
				"--bottom-overlay-background": bottomOverlayBackground
			}}
		>
			<div id="faq_section" className="faqs__container container container--mobile-small">
				<div className="faqs__wrapper">
					<h2 className="faqs__title medium-title">
						{title}
					</h2>

					<div className="faqs__accordion">
						{questions.map((question, index) => {


							return (
								<Accordion
									key={index}
									id={`question-${index}`}
									title={question.question}
									content={question.answer}
									blockClass="faqs"
									initialActive={index === initialOpenAccordion}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</section>
	);
}

Faqs.propTypes = {
	title: PropTypes.string.isRequired,
	questions: PropTypes.array.isRequired
};

export default Faqs;
