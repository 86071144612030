import React from "react";

const IconChevronLeft = () => (
	<svg width="6" height="10" viewBox="0 0 6 10" fill="#000000" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.45946 9.84016L5.7893 9.5126C5.89231 9.40951 5.94906 9.27235 5.94906 9.12577C5.94906 8.97926 5.89231 8.84194 5.7893 8.73885L2.05252 5.00224L5.79345 1.26131C5.89646 1.15838 5.95312 1.02107 5.95312 0.87456C5.95312 0.728055 5.89646 0.590655 5.79345 0.487646L5.46564 0.160001C5.25247 -0.0533338 4.90523 -0.0533338 4.69206 0.160001L0.222022 4.61402C0.119094 4.71695 0.0465725 4.8541 0.0465725 5.00191L0.0465725 5.00362C0.0465725 5.1502 0.119175 5.28736 0.222022 5.39029L4.67994 9.84016C4.78287 9.94325 4.92417 9.99984 5.07068 10C5.21726 10 5.35662 9.94325 5.45946 9.84016Z" />
	</svg>
);

export default IconChevronLeft;

