import React from "react";
import PropTypes from "prop-types";

const AppRichText = ({ content, backgroundColor, textColor }) => {
	return (
			<section
				className="app-rich-text"
				style={{
					"--background-color": backgroundColor,
					"--text-color": textColor
				}}
			>
				<div className="container container--medium app-rich-text__container">
					<div
						className="rte app-rich-text__content"
						dangerouslySetInnerHTML={{
							__html: content
						}}
					/>
				</div>
			</section>
	);
}

AppRichText.propTypes = {
	content: PropTypes.string.isRequired
};

export default AppRichText;
