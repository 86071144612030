import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage as Image } from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import { bannerSubtitleAnimation } from "../../settings/generalAnimations";
import { motion } from "framer-motion";
import PageHeader from "../../components/PageHeader";

const AppBanner = ({ subtitle, icon, backgroundImage, title, pageHeader }) => {
	const gatsbyImageData = backgroundImage.localFile?.childImageSharp?.gatsbyImageData;

	const headerOnBanner = pageHeader?.position === 'on';
	const headerBelowBanner = pageHeader?.position === 'below' || pageHeader?.position === 'bellow';

	const titleBelowBannerMarkup = headerBelowBanner && pageHeader?.title
		? <PageHeader {...pageHeader} isGeneral={true} />
		: null;
	const bannerTitleProps = headerOnBanner && pageHeader?.title
		? pageHeader
		: {};

	const bannerTitleMarkup = (
		<PageHeader
			{...bannerTitleProps}
			title={headerOnBanner && pageHeader?.title ? pageHeader?.title : title}
			implemented={true}
			className={"app-banner__title section-title"}
			isGeneral={headerOnBanner}
		/>
	);

	if(!gatsbyImageData) {
		return null;
	}

	const image = convertToBgImage(gatsbyImageData);

	return (
		<>
			<section className="app-banner">
				<BackgroundImage
					Tag="div"
					className="app-banner__wrapper"
					{...image}
				>
					<div className="app-banner__content-wrapper container container--medium">
						<div className="app-banner__columns">
							{icon && (
								<div className="app-banner__column app-banner__column--icon">
									<Image
										className="app-banner__icon"
										image={icon.localFile?.childImageSharp?.gatsbyImageData}
										alt={icon.altText || title}
										title={icon.altText || title}
									/>
								</div>
							)}

							<div className="app-banner__column app-banner__column--content">
								{bannerTitleMarkup}

								{subtitle && (
									<motion.h4
										className="app-banner__subtitle section-subtitle"
										{...bannerSubtitleAnimation}
									>
										{subtitle}
									</motion.h4>
								)}
							</div>
						</div>
					</div>
				</BackgroundImage>
			</section>

			{titleBelowBannerMarkup}
		</>
	);
};

AppBanner.propTypes = {
	icon: PropTypes.object.isRequired,
	backgroundImage: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string
};

export default AppBanner;
