import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage as Image } from "gatsby-plugin-image";

const AppFeatures = ({ features }) => {
	return (
		<div className="app-feature">
			<div className="app-feature__container container">
				<div className="app-feature__row">
					{features.map(({ image, title, content }, index) => {
						return (
							<div
								className="app-feature__item"
								key={index}
							>
								<div className="app-feature__item-wrapper">
									<div className="app-feature__item-image-wrapper">
										<Image
											className="app-feature__image"
											image={image.localFile?.childImageSharp?.gatsbyImageData}
											alt={image.altText || title}
											title={image.altText || title}
										/>
									</div>

									<h4 className="app-feature__item-title">
										{title}
									</h4>

									<div className="app-feature__item-content">
										{content}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

AppFeatures.propTypes = {
	features: PropTypes.array.isRequired
};

export default AppFeatures;
