import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import ProjectSummary from "../sections/Project/ProjectSummary";
import Faqs from "../sections/Faqs";
import AppRichText from "../sections/App/AppRichText";
import AppFeatures from "../sections/App/AppFeatures";
import ProjectGallery from "../sections/Project/ProjectGallery";
import AppBanner from "../sections/App/AppBanner";
import AppMeta from "../sections/App/AppMeta";
import DefaultHelmet from "../seo/DefaultHelmet";
import AppSmallTextBlocks from "../sections/App/AppSmallTextBlocks";
import AppFeature from "../sections/App/AppFeature";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";
import {graphql, useStaticQuery} from "gatsby";

const App = ({pageContext}) => {
    const {
        acfApp,
        title: appName,
        page_header: pageHeader,
    } = pageContext.app;

    const getSection = ({fieldGroupName, ...section}, index) => {
        const key = index;

        switch (fieldGroupName) {
            case 'app_Acfapp_Sections_AppBanner': {
                return (
                    <div key={key}>
                        <AppBanner
                            title={section.showTitle && appName}
                            pageHeader={pageHeader}
                            {...section}
                        />
                        <AppMeta/>
                    </div>
                );
            }

            case 'app_Acfapp_Sections_AppSummary': {
                return (
                    <ProjectSummary
                        key={key}
                        withManual
                        className="project-summary--secondary"
                        {...section}
                    />
                );
            }

            case 'app_Acfapp_Sections_AppFaqs': {
                return (
                    <Faqs
                        key={key}
                        {...section}
                    />
                );
            }

            case 'app_Acfapp_Sections_AppGallery': {
                return (
                    <ProjectGallery
                        key={key}
                        projectTitle={appName}
                        {...section}
                    />
                );
            }

            case 'app_Acfapp_Sections_AppRichText': {
                return (
                    <AppRichText
                        key={key}
                        {...section}
                    />
                )
            }

            case 'app_Acfapp_Sections_AppFeatures': {
                return (
                    <AppFeatures
                        key={key}
                        {...section}
                    />
                );
            }

            case 'app_Acfapp_Sections_AppFeature': {
                return (
                    <AppFeature
                        key={key}
                        projectTitle={appName}
                        {...section}
                    />
                );
            }

            case 'app_Acfapp_Sections_AppSmallTextBlocks': {
                return (
                    <AppSmallTextBlocks
                        key={key}
                        {...section}
                    />
                );
            }

            default: {
                return null;
            }
        }
    };

    const getSections = () => {
        const sections = acfApp?.sections;

        if (!Array.isArray(sections)) {
            return null;
        }

        return sections.map(getSection);
    };

    const pageTitle = acfApp?.metaData?.title || appName;
    const pageDescription = acfApp?.metaData?.description || `Page about UTD application: ${appName}`;
    const pageKeywords = "UTD, Shopify App, Shopify"

    useBeaconWidget(beaconWidgetIds.APP);

    const {site} = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                        title
                        author
                    }
                }
            }
        `
    );

    const microdata = {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": appName,
        "image": [
            site?.siteMetadata?.siteUrl + acfApp.icon.localFile.childImageSharp.gatsbyImageData.images.fallback.src,
        ],
        "author": [{
            "@type": "Organization",
            "name": site?.siteMetadata?.title,
            "url": site?.siteMetadata?.siteUrl
        }]
    };

    return (
        <DefaultLayout>
            <DefaultHelmet title={pageTitle} description={pageDescription} keywords={pageKeywords} microdata={microdata}/>

            {getSections()}
        </DefaultLayout>
    );
};

export default App;
