import React from "react";
import PropTypes from "prop-types";
import {GatsbyImage as Image} from "gatsby-plugin-image";

const AppFeature = ({projectTitle, title, description, image, imagePosition}) => {
    const TextContentMarkup = (
        <>
            {title && (
                <h2 className="app-feature__title medium-title">
                    {title}
                </h2>
            )}

            {description && (
                <p className="app-feature__description">
                    {description}
                </p>
            )}
        </>
    );
    const leftImage = (
        <div className="app-feature__wrapper container container--medium">
            <div className="app-feature__content hide-tablet-and-up">
                {TextContentMarkup}
            </div>

            <div className="app-feature__columns">
                <div className="app-feature__column app-feature__column--left">
                    <Image
                        className="app-feature__image app-feature__image--left"
                        image={image.localFile?.childImageSharp?.gatsbyImageData}
                        alt={image.altText || projectTitle}
                        title={image.altText || projectTitle}
                    />
                </div>

                <div className="app-feature__column app-feature__column--right">
                    {TextContentMarkup}
                </div>
            </div>
        </div>
    )
    const rightImage = (
        <div className="app-feature__wrapper container container--medium">
            <div className="app-feature__content hide-tablet-and-up">
                {TextContentMarkup}
            </div>

            <div className="app-feature__columns">
                <div className="app-feature__column app-feature__column--right">
                    {TextContentMarkup}
                </div>

                <div className="app-feature__column app-feature__column--left">
                    <Image
                        className="app-feature__image app-feature__image--right"
                        image={image.localFile?.childImageSharp?.gatsbyImageData}
                        alt={image.altText || projectTitle}
                        title={image.altText || projectTitle}
                    />
                </div>
            </div>
        </div>
    )

    return (
        <section className="app-feature">
            {imagePosition === "left" ? leftImage : rightImage}
        </section>
    );
};

AppFeature.propTypes = {
    projectTitle: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    leftImage: PropTypes.object.isRequired,
    rightImage: PropTypes.object.isRequired
};

export default AppFeature;
