import React, {isValidElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import classNames from "classnames";
import {AnimatePresence, motion} from "framer-motion";
import PropTypes from "prop-types";

import useMediaQuery from "../hooks/useMediaQuery";
import IconArrowDown from "../icons/IconArrowDown";

const Accordion = ({
    initialActive = false,
    content,
    title,
    blockClass,
    id,
    useOpacity = true,
    onOpenerClick,
    chevronType = 'cross', // 'cross' | 'arrow' | 'none'
    ...props
}) => {
    const [active, setActive] = useState(initialActive);

    useEffect(() => {
        setActive(initialActive);
    }, [initialActive]);

    const accordionRef = useRef();

    const panelClasses = classNames(`rte ${blockClass}__panel`, props.className, {
        [`${blockClass}__panel--active`]: active
    });

    const Content = () => {
        if (isValidElement(content)) {
            return (
                <div className={`${blockClass}__content`}>
                    {content}
                </div>
            );
        }

        return <div className={`${blockClass}__content`} dangerouslySetInnerHTML={{__html: content}}/>;
    };

    const Chevron = useMemo(() => (
        <>
            {chevronType === 'cross' && <div className="cross"/>}
            {chevronType === 'arrow' && (
                <div className="arrow">
                    <IconArrowDown />
                </div>
            )}
        </>
    ), [chevronType]);

    const toggleAccordion = useCallback(() => {
        if (onOpenerClick && typeof onOpenerClick === "function") {
            onOpenerClick();
        }

        setActive(prevActive => !prevActive)
    }, [onOpenerClick])

    return (
        <div data-id={id} className={panelClasses} ref={accordionRef}>
            <button
                type="button"
                onClick={toggleAccordion}
                className={`${blockClass}__button`}
            >
                {title}
                {Chevron}
            </button>

            <AnimatePresence initial={false}>
                {active && (
                    <motion.div
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: {
                                opacity: 1,
                                height: "auto"
                            },
                            collapsed: {
                                opacity: useOpacity ? 0 : 1,
                                height: 0
                            }
                        }}
                        transition={{
                            duration: 0.8,
                            ease: [0.1, 0.62, 0.23, 0.98]
                        }}
                        className={`${blockClass}__inner`}
                    >
                        <Content/>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

Accordion.propTypes = {
    content: PropTypes.any,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    blockClass: PropTypes.string.isRequired,
    useOpacity: PropTypes.bool,
    initialActive: PropTypes.bool
};

export default Accordion;
